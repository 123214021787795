import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/styles.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Hello!
          </h2>
          <figure className="kg-card kg-image-card kg-width-full">
            {/* <Img
              fluid={data.benchAccounting.childImageSharp.fluid}
              className="kg-image"
            /> */}
            <div className="videoSection">
              <div className="videowrapper">
                <iframe
                  title="Welcome Video"
                  width="100%"
                  src="https://www.youtube.com/embed/4P9y5KiHgm0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ margin: "0 auto", height: "100%" }}
                ></iframe>
              </div>
            </div>
            {/* <figcaption>Large imagery is at the heart of this theme</figcaption> */}
          </figure>

          <h3 id="dynamic-styles">How's your day?</h3>
          <p>
            CheroCaptures provides photography and videography services. How
            does the chero name picked? It's taken from the word <i>cherish</i>.
          </p>
          <p>
            Having a family or couple sessions / events? We are ready to cherish
            your life moments. Birthdays, parties, engagement, weddings,
            gatherings, you name it.
          </p>
          <p>Cheers!</p>
          <p>
            <Link to="/contactus">Contact us here</Link>
          </p>

          <h3>Behind the Chero</h3>
          <div className="personasWrapper">
            <Img
              fluid={data.nathan.childImageSharp.fluid}
              className="illustration"
            />
            <h5 className="personas">Agustinus Nathaniel</h5>
            <p style={{ fontSize: "1.2rem" }}>
              Founder, Main Videographer & Editor, Based in Jakarta
            </p>
          </div>
          {/* <div className="personasWrapper" style={{textAlign:"right"}}>
            <Img
              fluid={data.nathan.childImageSharp.fluid}
              className="illustration alignRight"
            />
            <h5 className="personas">Robin Colinkang</h5>
            <p style={{ fontSize: "1.2rem" }}>
              Photographer, Based in Jakarta
            </p>
          </div> */}
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    nathan: file(relativePath: { eq: "agustinusnathaniel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
